import { BrowserRouter, Outlet, Route, Routes, Switch } from "react-router-dom";
import "./App.css";
import Bio from "./components/Bio.js";
import Footer from "./components/Footer.js";
import Gallery from "./components/Gallery.js";
import Navbar from "./components/Navbar.js";
import Projects from "./components/Projects.js";
import Portfolio from "./components/Projects/Portfolio.js";
// import Sidebar from "./components/Sidebar.js";
import Fashion from "./components/Fashion.js";
import ChampStatus from "./components/Projects/ChampionshipStatus.js";
import FootballSchedule from "./components/Projects/FootballSchedule.js";
import TimeTracker from "./components/Projects/TimeTracker.js";
import TypingGame from "./components/Projects/TypingGame.js";
// import Resume from "./components/Resume.js";

function App() {
  return (
    <div>
      <Navbar />
      <div className="content">
        <Routes>
          <Route exact path="/" element={<Bio />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Projects/Portfolio" element={<Portfolio />} />
          <Route
            path="/Projects/FootballScheduler"
            element={<FootballSchedule />}
          />
          <Route path="/Projects/TimeTracker" element={<TimeTracker />} />
          <Route
            path="/Projects/ChampionshipStatus"
            element={<ChampStatus />}
          />
          <Route path="/Projects/TypingGame" element={<TypingGame />} />
          <Route path="/Fashion" element={<Fashion />} />

          {/* <Route path="/Resume" element={<Resume />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
