import { LuGithub } from "react-icons/lu";
import calendar from "../assets/projects/footballSchedule/calendar.JPG";
import event from "../assets/projects/footballSchedule/event.JPG";
import firstDropdown from "../assets/projects/footballSchedule/firstDropdown.jpg";
import scheduler from "../assets/projects/footballSchedule/scheduler.JPG";
import secondDropdown from "../assets/projects/footballSchedule/secondDropdown.jpg";
import "./FootballSchedule.css";
import "./Project.css";

function FootballSchedule() {
  return (
    <div className="projectContent">
      <div className="title">
        <h1>Football Scheduler</h1>

        
      </div>
      <div className="skill">
        <li>React.js</li>
        <li>Node.js</li>
        <li>Express.js</li>
        <li>JavaScript</li>
        <li>MongoDB</li>
        <li>HTML</li>
        <li>CSS</li>
        <li>Git</li>
        <li>RESTful APIs</li>
      </div>
      <br />
      <br />

      <h2>Motivation:</h2>
      <p>
        I follow multiple football/soccer teams across several leagues. Most
        leagues have their own calendar that you can integrate into Google
        Calendar, however this crowds the list of calendars and makes hiding
        them slightly more tedious.
      </p>
      <h2>Solution:</h2>
      <p>
        I decided that it would be good practice to make a web-app that takes
        match data for selected teams and puts it into one calendar.
      </p>
      <h2>Minimum viable product:</h2>
      <p>
        For the front end, it has a form to select the teams and a list for
        those already added. I used
        <a href="https://www.football-data.org/"> football-data.org</a>'s api
        for match and team information.
      </p>
      <img src={scheduler} alt="web app" height="350" />
      <p>
        <b>User flow:</b> The league is selected with the first dropdown. The
        second dropdown displays the teams in that league. When the "add" button
        is clicked, a pop-up notifies the user that the team was added, and it
        appears in the list. To delete a team, the user clicks the delete button
        for that team. If the user tries to add a team that is already in the
        list, a notification will appear. <br />
        <div className="user-flow">
          <img src={firstDropdown} alt="web app" height="300" />
          <img src={secondDropdown} alt="web app" height="500" />
        </div>
        <br />
        {/* <b>Backend:</b> The league options are hard-coded for the leagues I
        follow. Once a league is selected, it calls the api and retrieves all
        current teams in that league and adds them to the second dropdown. Each
        season, as teams are relegated or promoted, the options will also
        change. Once a team is selected and the "add" button is clicked, it
        retrieves all scheduled matches for that team from the api, passes it to
        a Google Calendar api that adds them to a calendar. Teams that have been
        added are tracked in a MongoDB database and displayed in the list.
        Duplication detection and deletion are handled here. */}
        <div className="calendar">
          <img src={calendar} alt="web app" height="700" />
          <img src={event} alt="web app" height="200" />
        </div>
      </p>

      <p>
        <b>Roadmap:</b>{" "}
        <li>
          Authentication for the Google account is only hadled on page reload.
        </li>{" "}
        <li>
          No detection of duplicate matches in calendar. E.g., adding Arsenal
          and Manchester city would create two events for the same match.
        </li>
        <li>
          The delete button only deletes the team from database and not the
          matches from google calendar.
        </li>
        <li>
          The calendar the events are added to is currently hardcoded. Not
          currently an issue as I am the only user.
        </li>
      </p>

      <div className="projectToDo">
        <b>Planned updates/changes:</b>

        <li>A login screen.</li>
        <li>Being able to select which calendar events are added to.</li>
        <li>Make the delete button also delete events from the calendar.</li>
      </div>
      <div className="githubRepo">
        <a
          href="https://github.com/kaiRO99/FootballScheduler"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LuGithub className="logo" />
          GitHub
        </a>
      </div>
    </div>
  );
}

export default FootballSchedule;
