import { LuGithub } from "react-icons/lu";
import endgame from "../assets/projects/TypingGame/endgame.JPG";
import gameplay from "../assets/projects/TypingGame/gameplay.JPG";
import start from "../assets/projects/TypingGame/start.JPG";

import "./FootballSchedule.css";
import "./Project.css";
import "./TypingGame.css";

function TypingGame() {
  return (
    <div className="projectContent">
      <div className="title">
        <h1>Typing Game</h1>
      </div>
      <div className="skill">
        <li>React.js</li>
        <li>JavaScript</li>
        <li>CSS</li>
        <li>Git</li>
      </div>
      <br />
      <br />
      <p>
        A typing game created with React. Words fall from the top of the screen
        and players need to type them before they reach the bottom. Players gain
        5 point for each correctly typed word. Once a word reaches the bottom, a
        life is lost. Players start with 5 lives.
      </p>
      <div className="TGscreenshots">
        <img src={start} alt="start" height="300" />
        <img src={gameplay} alt="gameplay" height="300" />
        <img src={endgame} alt="endgame" height="300" />
      </div>

      <br />
      <div className="githubRepo">
        <a
          href="https://github.com/kaiRO99/typing_game"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LuGithub className="logo" />
          GitHub
        </a>
      </div>
    </div>
  );
}

export default TypingGame;
