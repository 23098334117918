import { LuArrowUpRight } from "react-icons/lu";
import "./Bio.css";
// import resume from "./assets/KaiRO_Resume.pdf";
import picture from "./assets/me.jpeg";

const Bio = () => {
  const resume = "/KaiRO_Resume.pdf";
  //TODO: make bio section block
  return (
    <div className="bioPage">
      <div className="bio">
        <div class="homepagetxt">
          <p>
            I’m <i>Kai</i>, a creative and hardworking individual, looking to
            start a career in software development. I have a background in
            mental health psychology, with a concentration in computer science.
            This portfolio is an ongoing record of my projects and interests.
          </p>
        </div>
        <div class="homepageimg">
          <img src={picture} alt="me" height="600" />
        </div>
      </div>
      <div className="resume">
        <a href={resume} target="_blank" rel="noopener noreferrer">
          View Résumé
          <LuArrowUpRight className="arrow" />
        </a>
      </div>
    </div>
  );
};

export default Bio;
