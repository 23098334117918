import { LuGithub } from "react-icons/lu";
import f1 from "../assets/projects/championshipStatus/f1.JPG";
import home from "../assets/projects/championshipStatus/home.JPG";
import laliga from "../assets/projects/championshipStatus/laliga.JPG";
import prem from "../assets/projects/championshipStatus/premier_league.JPG";

import "./FootballSchedule.css";
import "./Project.css";

function ChampStatus() {
  return (
    <div className="projectContent">
      <div className="title">
        <h1>Championship Status</h1>
      </div>
      <div className="skill">
        <li>Django</li>
        <li>Python</li>
        <li>MongoDB</li>
        <li>Git</li>
        <li>APIs</li>
      </div>
      <br />
      <br />

      <p>
        Track when a team/driver is mathematically out of their respective
        championship. The data is retrieved from APIs once after 6PM on Sundays
        and Saturdays, saving it to a MongoDB database. The rest of the week,
        data is retrieved from the database.
      </p>

      <img src={home} alt="home" height="200" />
      <img src={f1} alt="f1" height="700" />
      <img src={laliga} alt="laliga" height="700" />
      <img src={prem} alt="premier league" height="700" />
      <br />
      <p>
        {" "}
        APIs used:
        <br />
        <ul>
          <li>
            <a href="https://documenter.getpostman.com/view/11586746/SztEa7bL#intro">
              Formula 1 data
            </a>
          </li>
          <li>
            <a href="https://www.football-data.org/documentation/quickstart">
              Football data
            </a>
          </li>
        </ul>
      </p>
      <div className="githubRepo">
        <a
          href="https://github.com/kaiRO99/Championship_Status"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LuGithub className="logo" />
          GitHub
        </a>
      </div>
    </div>
  );
}

export default ChampStatus;
